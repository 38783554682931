var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('validation-observer',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-4 ml-0",attrs:{"color":"primary","icon":"mdi-clipboard-outline","title":"Consultar Aprovações","inline":""}},[_c('v-row',{staticClass:"mt-5"},[_c('v-col',{staticClass:"pb-0 pt-1",attrs:{"cols":"6"}},[_c('input-month',{attrs:{"minMonth":'2023-01',"label":"Competência de*","rules":"required|min:7","selectedMonth":_vm.competencia_de},on:{"monthSelected":_vm.setDataDe}})],1),_c('v-col',{staticClass:"pb-0 pt-1",attrs:{"cols":"6"}},[_c('input-month',{attrs:{"minMonth":'2023-01',"label":"Competência até*","rules":"required|min:7","selectedMonth":_vm.competencia_ate},on:{"monthSelected":_vm.setDataAte}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticStyle:{"float":"right"},attrs:{"id":"btn-consulta-detalhada","color":"primary","dark":"","elevation":"1","relative":"","text":"","medium":"","disabled":invalid,"loading":_vm.downloadingConsulta},on:{"click":function($event){return _vm.baixarConsultaDetalhada()}}},[_c('v-icon',{attrs:{"left":"","size":"30px"}},[_vm._v(" mdi-microsoft-excel ")]),_vm._v(" Consulta Detalhada ")],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-expansion-panels',{attrs:{"accordion":"","multiple":""},model:{value:(_vm.panels),callback:function ($$v) {_vm.panels=$$v},expression:"panels"}},_vm._l((_vm.bases),function(base){return _c('v-expansion-panel',{key:base.value},[_c('v-expansion-panel-header',[_c('strong',[_vm._v(_vm._s(base.text))])]),_c('v-expansion-panel-content',[_c('v-data-table',{staticClass:"data-table",attrs:{"multi-sort":"","dense":"","headers":_vm.headers,"items":_vm.items[base.value],"options":{ itemsPerPage: -1 },"headerProps":{ sortByText: 'Ordenar Por' },"no-data-text":"Nenhum registro disponível","no-results-text":"Nenhum registro correspondente encontrado","mobile-breakpoint":10},scopedSlots:_vm._u([{key:"item.perc_aprovados",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.perc_aprovados === 100 ? 'success--text font-weight-bold' : 'error--text font-weight-bold'},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.perc_aprovados))+" % ")])]}},{key:"item.perc_reprovados",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.perc_reprovados === 0 ? 'success--text font-weight-bold' : 'error--text font-weight-bold'},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.perc_reprovados))+" % ")])]}},{key:"item.perc_nao_validados",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.perc_nao_validados === 0 ? 'success--text font-weight-bold' : 'info--text font-weight-bold'},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.perc_nao_validados))+" % ")])]}}],null,true)})],1)],1)}),1)],1)],1)],1)],1)],1)]}}])}),_c('v-overlay',{attrs:{"value":_vm.loading,"opacity":0.85}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }